
























import { Vue, Component, Prop } from "vue-property-decorator"

@Component({
  name: "empty-state",
})
export default class EmptyState extends Vue {
  @Prop({ type: String, required: true }) readonly title!: string
  @Prop({ type: String, required: false }) readonly icon!: string
  @Prop({ type: String, required: false }) readonly description!: string
  @Prop({ type: String, required: false }) readonly src!: string
  @Prop({ type: Number, required: false, default: 300 }) readonly size!: number
  @Prop({ type: Boolean, required: false, default: false }) readonly image!: boolean
}
